<template>
  <footer class="kft-footer">
    <div v-for="(columnData, i) in columnsData" :key="columnData.title">
      <slot :name="`footer-column-${i}`">
        <FooterColumn :column-data="columnData" />
      </slot>
    </div>
    <div>
      <h5 class="kft-footer-column__title">
        {{ $t("footer.paymentMethodsEmbedded") }}
      </h5>
      <SfImage
        v-for="item in paymentMethods"
        :key="item.slug"
        :alt="item.alt"
        :src="`/media/svg/card-logos/${item.slug}.svg`"
        class="kft-footer__payment-methods me-2"
        height="30"
        width="auto"
      />
    </div>
    <div>
      <h5 class="kft-footer-column__title">
        {{ $t("footer.socialEmbedded") }}
      </h5>
      <slot name="footer-social-media-buttons">
        <a
          v-for="item in socialMedia"
          :key="item.name"
          :href="item.url"
          target="_blank"
        >
          <SfImage
            :alt="item.name"
            :src="'/icons/' + item.name + '.svg'"
            class="kft-footer__social-media-buttons me-2"
            height="32"
            width="32"
          />
        </a>
      </slot>
    </div>
    <div>
      <h5 class="kft-footer-column__title">
        {{ $t("general.language") }}
      </h5>
      <LocaleSelector />
    </div>
  </footer>
</template>

<script>
import { FooterColumn, SfImage, LocaleSelector } from "@konfetti-ui/vue";

export default {
  name: "KftFooter",
  components: {
    FooterColumn,
    SfImage,
    LocaleSelector
  },
  props: {
    supplier: {
      type: String | Object,
      required: true
    },
    columnsData: {
      type: Array,
      required: true
    }
  },
  setup(props, { root: { $i18n } }) {
    const paymentMethods = [
      {
        alt: "Mastercard",
        slug: "mastercard"
      },
      {
        alt: "Visa",
        slug: "visa"
      },
      {
        alt: "Sofort",
        slug: "sofort"
      },
      {
        alt: "Paypal",
        slug: "paypal"
      }
    ];

    const socialMedia = [
      {
        name: "facebook",
        url: $i18n.t("footer.socialMedia.links.facebook")
      },
      {
        name: "linkedin",
        url: $i18n.t("footer.socialMedia.links.linkedin")
      },
      {
        name: "instagram",
        url: $i18n.t("footer.socialMedia.links.instagram")
      }
    ];

    return {
      paymentMethods,
      socialMedia
    };
  }
};
</script>
<style lang="scss" scoped>
@import "~@konfetti-ui/shared/styles/components/organisms/KftFooter.scss";
</style>
