























































import { KftButton } from "@konfetti-ui/vue";
import { KftNotificationType, useNotifications } from "../../../composables";
export default {
  name: "KftNotifications",

  components: {
    KftButton,
  },

  setup() {
    const { notifications, addNotification, handleCloseNotification } =
      useNotifications();

    const iconByNotificationType: Record<KftNotificationType, string> = {
      [KftNotificationType.INFO]: "bi-info-circle-fill",
      [KftNotificationType.SUCCESS]: "bi-check-circle-fill",
      [KftNotificationType.WARNING]: "bi-exclamation-circle-fill",
      [KftNotificationType.ERROR]: "bi-exclamation-triangle-fill",
    };

    return {
      iconByNotificationType,
      notifications,
      KftNotificationType,

      addNotification,
      handleCloseNotification,
    };
  },
};
