<template>
  <div :class="{ 'device-mobile': $device.isMobile }">
    <LazyHydrate when-idle>
      <AppHeader
        v-if="supplier && supplier.data"
        :supplier-permalink="supplierGetters.getPermalink(supplier.data)"
        :logo="supplierGetters.getHeaderLogo(supplier.data)"
        :should-enable-giftcard="shouldEnableGiftcard"
      />
    </LazyHydrate>
    <!-- END::Header -->

    <client-only>
      <KftNotifications />
    </client-only>

    <!-- BEGIN::Page Content -->
    <div id="layout">
      <nuxt :key="$route.fullPath" />
      <client-only>
        <CartSidebar :show-giftbox="false" />
        <DateRequestSidebar :is-supplier="true" />
        <DateWaitlistSidebar />
      </client-only>
    </div>
    <!-- END::Page Content -->

    <!-- BEGIN::Footer -->
    <LazyHydrate when-idle>
      <KftFooter
        v-if="footerData"
        :supplier="supplier"
        :columns-data="footerData"
      >
        <template v-if="footerData[2]" #footer-column-2>
          <div>
            <h5 class="booking-solution-logo-title">
              {{ footerData[2].title }}
            </h5>
          </div>
          <a
            :href="
              supplier
                ? $fullLocalizedUrl(`/p/${supplier.data.permalink}`)
                : $fullLocalizedUrl('/')
            "
            class="kft-header__logo"
            data-cy="app-header-url_logo"
          >
            <img
              :alt="$t('general.titles.home') + $t('general.titles.base')"
              height="22.75"
              src="/logos/full-logo-color-black.svg"
              width="135"
            />
          </a>
        </template>
      </KftFooter>
    </LazyHydrate>
    <!-- END::Footer -->
  </div>
</template>

<script>
import LazyHydrate from "vue-lazy-hydration";
import { KftFooter } from "@konfetti-ui/vue";
import { onSSR } from "@konfetti-core/core";
import { supplierGetters, useSupplier } from "@konfetti/composables";
import { onMounted, useAsync, computed } from "@nuxtjs/composition-api";
import { useStrapiSingleType } from "@konfetti/composables/src";
import {
  useDefaultErrorHandlers,
  useRegisterCartValidationPolling,
} from "../composables";
import KftNotifications from "../components/organisms/KftNotifications/KftNotifications.vue";
import SeoHelper from "~/helpers/seo/SeoHelper";
import { AppHeader, CartSidebar, DateRequestSidebar } from "~/components";
import { getSupplierSubdomain } from "~/helpers";

export default {
  name: "SupplierLayout",
  components: {
    KftFooter,
    KftNotifications,
    AppHeader,
    CartSidebar,
    DateRequestSidebar,
    LazyHydrate,
    DateWaitlistSidebar: () =>
      import(
        "~/components/organisms/DateWaitlistSidebar/DateWaitlistSidebar.vue"
      ),
  },

  setup(props, { ssrContext, root: { context, error } }) {
    const { fnLoadSupplierByAlias, supplier } = useSupplier("supplier-layout");

    const { registerDefaultErrorHandlers } = useDefaultErrorHandlers();

    const { singleType: bookingSolutionFooter, loadSingleType } =
      useStrapiSingleType("supplier-homepage");

    useAsync(() =>
      loadSingleType(
        `booking-solution-footer?locale=${context.i18n.locale}&populate[footerBlock][populate]=*`,
      ),
    );

    const shouldEnableGiftcard = computed(() =>
      supplierGetters.getHasGiftcardEmbedded(supplier.value?.data),
    );

    const footerData = computed(() => {
      const footerBlock =
        bookingSolutionFooter.value?.data?.attributes?.footerBlock;
      if (supplier.value) {
        const phone = supplier.value?.data?.phone;
        const email = supplier.value?.data?.email;
        const address = supplier.value?.data?.address;
        const website = supplier.value?.data?.website;
        footerBlock[0].items = [
          ...(phone ? [{ id: 0, label: phone, link: `tel:${phone}` }] : []),
          ...(email ? [{ id: 1, label: email, link: `mailto:${email}` }] : []),
          ...(address ? [{ id: 2, label: address }] : []),
          ...(website ? [{ id: 3, label: website, link: website }] : []),
        ];
      }

      return footerBlock;
    });

    useRegisterCartValidationPolling();

    onMounted(() => {
      registerDefaultErrorHandlers();
    });

    onSSR(async () => {
      const subdomain = await getSupplierSubdomain(
        ssrContext?.nuxt || context.nuxtState,
        context.$config,
      );

      await fnLoadSupplierByAlias(subdomain);
      if (supplier.value === null) {
        error({
          statusCode: 404,
          message: "Error fetching supplier...",
        });
      }
      context.$config.supplierId = supplier.value?.data?.id;
    });
    return {
      supplier,
      supplierGetters,
      footerData,
      shouldEnableGiftcard,
    };
  },

  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: false });

    const seoHelper = new SeoHelper(this, {
      allowedParams: [],
      shouldBeIndexed: false,
      canonicalLink: this.$fullLocalizedUrl(
        `/p/${this.supplier?.data?.permalink}`,
      ),
    });

    const seoHelperMeta = seoHelper.getHeadMeta();

    return {
      ...i18nHead,
      link: seoHelper.getI18nHead().link,
      title:
        this.supplierGetters.getName(this.supplier?.data) ||
        this.$t("general.titles.embeddedBase"),
      meta: [...seoHelperMeta],
    };
  },
};
</script>

<style lang="scss">
.supplier-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#layout {
  flex: 1 0 auto;
  box-sizing: border-box;
  padding: var(--navbar-height) var(--spacer-2);

  @media (min-width: 375px) {
    padding: var(--navbar-height) var(--spacer-4);
  }

  @include for-desktop {
    max-width: 1240px;
    margin: auto;
  }
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
}

.booking-solution-logo-title {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: -0.015rem;
  margin-bottom: 1.5rem;
}

// Reset CSS
html {
  width: auto;
  @include for-tablet-and-down {
    overflow-x: hidden;
  }
}

body {
  overflow-x: hidden;
  color: var(--c-text);
  font-size: var(--font-size--base);
  font-family: var(--font-family--primary);
  margin: 0;
  padding: 0;
}
</style>
