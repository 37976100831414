<template>
  <div class="kft-footer-column">
    <h5 class="kft-footer-column__title">{{ columnData.title }}</h5>
    <ul class="kft-footer-column__list">
      <li
        v-for="item in columnData.items"
        :key="item.label"
        class="kft-footer-column__item"
      >
        <KftLink v-if="item.link" :link="getLocalePath(item.link)">
          {{ item.label }}
        </KftLink>
        <span v-else>{{ item.label }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { KftLink } from "@konfetti-ui/vue";
export default {
  name: "FooterColumn",
  components: {
    KftLink,
  },
  props: {
    columnData: {
      type: Object | String,
      required: true,
    },
  },
  setup(props, { root: { localePath } }) {
    const getLocalePath = (path) => {
      return path.startsWith("https://") ||
        path.startsWith("mailto:") ||
        path.startsWith("tel:")
        ? path
        : localePath(path);
    };

    return {
      getLocalePath,
    };
  },
};
</script>

<style lang="scss">
@import "~@konfetti-ui/shared/styles/components/molecules/FooterColumn.scss";
</style>
