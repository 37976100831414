var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kft-notifications",class:{
    'kft-notifications--search-page':
      _vm.$route.name && _vm.$route.name.match(/search___[a-z]{2}-[a-z]{2}/),
  }},[_c('transition',{attrs:{"mode":"out-in","name":"sf-collapse-top"}},[(_vm.notifications.length > 0)?_c('div',{staticClass:"kft-notifications__container",class:{
        'kft-notifications--info':
          _vm.notifications[0].type === _vm.KftNotificationType.INFO,
        'kft-notifications--success':
          _vm.notifications[0].type === _vm.KftNotificationType.SUCCESS,
        'kft-notifications--warning':
          _vm.notifications[0].type === _vm.KftNotificationType.WARNING,
        'kft-notifications--error':
          _vm.notifications[0].type === _vm.KftNotificationType.ERROR,
      }},[_c('div',{staticClass:"kft-notifications__content",class:{ 'justify-content-center': !_vm.notifications[0].htmlContent }},[(_vm.notifications[0].htmlContent)?_c('div',{staticClass:"kft-notifications__html-content",domProps:{"innerHTML":_vm._s(_vm.notifications[0].htmlContent)}}):[_c('span',{staticClass:"kft-notifications__icon"},[_c('i',{class:("bi " + (_vm.iconByNotificationType[_vm.notifications[0].type]))})]),_vm._v(" "),_c('span',{staticClass:"kft-notifications__message"},[_vm._v(_vm._s(_vm.notifications[0].message))])],_vm._v(" "),_c('span',{staticClass:"kft-notifications__close-button"},[_c('KftButton',{on:{"click":_vm.handleCloseNotification}},[_c('i',{staticClass:"bi bi-x-lg"})])],1)],2)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }