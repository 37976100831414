import Vue from "vue";
import { NuxtI18nMeta } from "@nuxtjs/i18n/types/vue";
import {
  LinkPropertyBase,
  LinkPropertyHref,
  LinkPropertyHrefCallback,
  MetaPropertyCharset,
  MetaPropertyEquiv,
  MetaPropertyMicrodata,
  MetaPropertyName,
  MetaPropertyProperty,
} from "vue-meta/types/vue-meta";
import { getSupplierSubdomain } from "@konfetti/composables/src";
import { withTrailingSlash } from "../url";
import { indexedRoutes } from "./IndexedRoutes";

type SeoHelperConfigLinkObj = {
  href: string;
  rel: string;
  [key: string]: string;
};

interface HeadMetaHelperConfig {
  allowedParams: string[];
  shouldBeIndexed: boolean;
  canonicalLink?: string;
  disableCanonicalLink?: boolean;
  meta: any;
  link: SeoHelperConfigLinkObj[];
}

type MetaItem =
  | MetaPropertyCharset
  | MetaPropertyEquiv
  | MetaPropertyName
  | MetaPropertyMicrodata
  | MetaPropertyProperty;
type LinkItem = LinkPropertyBase | LinkPropertyHref | LinkPropertyHrefCallback;

/**
 * @description //
 */

class SeoHelper {
  private vm: Vue;
  private readonly allowedParams: string[];
  private readonly shouldBeIndexed: boolean;
  private readonly canonicalLink: string;
  private readonly disableCanonicalLink: boolean;
  private readonly i18nHead: NuxtI18nMeta;
  private readonly i18nHeadMeta: MetaItem[];
  private meta: any;
  private link: SeoHelperConfigLinkObj[];

  constructor(vm: Vue, config: HeadMetaHelperConfig) {
    this.vm = vm;
    this.allowedParams = config.allowedParams || [];
    this.shouldBeIndexed = config.shouldBeIndexed || false;
    this.canonicalLink = config.canonicalLink || null;
    this.disableCanonicalLink = config.disableCanonicalLink || null;
    this.meta = config.meta || true;
    this.link = config.link || [];

    this.i18nHead = (this.vm as any).$nuxtI18nHead({
      addSeoAttributes: {
        canonicalQueries: this.allowedParams,
      },
    });

    this.i18nHeadMeta = [...this.i18nHead.meta];

    this.init();
  }

  public getCanonicalLinkTag(): LinkItem {
    return this.i18nHead.link.find((item) => item.hid === "i18n-can") || null;
  }

  public getHreflangTags(): LinkItem[] {
    return (
      this.i18nHead.link.filter((item) => item.hreflang !== undefined) || null
    );
  }

  public getCanonicalLink(): string {
    return this.getCanonicalLinkTag()?.href || null;
  }

  /**
   * @description
   */
  public createOrOverwriteMetaTag(
    metaHid: string,
    metaNewContent: string,
    metaNewName: string = null,
  ): void {
    const metaTag = this.i18nHeadMeta.find((item) => item.hid === metaHid);

    if (metaTag) {
      metaTag.content = metaNewContent;
    } else {
      this.i18nHeadMeta.push({
        hid: metaHid,
        name: metaNewName || metaHid,
        content: metaNewContent,
      });
    }
  }

  /**
   * @description
   */
  public createOrOverwriteLinkTag(
    hid: string,
    linkObj: SeoHelperConfigLinkObj,
  ): void {
    const tag = this.i18nHead.link.find((item) => item.hid === hid);

    if (tag) {
      Object.assign(tag, linkObj);
    } else {
      this.i18nHead.link.push({
        hid,
        ...linkObj,
      });
    }
  }

  /**
   * @description Checks if the params are allowed
   * If there is a not allowed parameter, marks as noindex
   */
  public isQueryIsCanonical(): boolean {
    return Object.keys(this.getQueryParameters()).every((param) =>
      this.allowedParams.includes(param),
    );
  }

  public getHeadMeta(): MetaItem[] {
    return this.i18nHeadMeta;
  }

  public getI18nHead(): NuxtI18nMeta {
    return this.i18nHead;
  }

  /**
   * @description initialize helper
   */
  private init() {
    this.handlePageIndex();

    this.handleMetaTags();
    this.handleLinkTags();

    this.handleCanonicalLink();
    this.forceTrailingSlashOnHrefLangTags();
  }

  private getShouldBeIndexed() {
    return this.shouldBeIndexed;
  }

  private setCanonicalLink() {
    if (this.canonicalLink) {
      this.getCanonicalLinkTag().href = this.canonicalLink;
    }
  }

  private handleCanonicalLink() {
    this.setCanonicalLink();

    if (this.disableCanonicalLink) {
      delete this.getCanonicalLinkTag().href;
      delete this.getCanonicalLinkTag().rel;
    } else {
      this.forceTrailingSlashOnCanonical();
    }
  }

  private handlePageIndex() {
    const defaultRobotsContentArr = ["max-image-preview:large"];
    const getRobotsMetaContent = (content) =>
      [content, defaultRobotsContentArr].join(", ");

    // Doesn't index as default
    this.createOrOverwriteMetaTag(
      "robots",
      getRobotsMetaContent("noindex, follow"),
    );

    const locale = this.vm.$i18n.locale;
    let currentRoute =
      "/" + this.vm.$route.fullPath.replace(locale, "").replace(/\//g, "");

    if (currentRoute !== "/") {
      currentRoute = currentRoute + "/";
    }

    // Indexed in case:
    // - the route is listed in indexedRoutes array
    // - SeoHelper config is marked as shouldIndex
    if (
      indexedRoutes.find((ir) => ir.path === currentRoute) ||
      this.getShouldBeIndexed()
    ) {
      this.createOrOverwriteMetaTag(
        "robots",
        getRobotsMetaContent("index, follow"),
      );
    }

    // If the query is not canonical, then it can't be indexed by any means
    // If x-param header is 'param', don't index
    if (
      this.vm.$ssrContext?.req?.headers["x-param"] === "param" ||
      !this.isQueryIsCanonical() ||
      (this.vm.$config.nodeEnv !== "production" &&
        this.vm.$config.nodeEnv !== "prod") ||
      getSupplierSubdomain(this.vm.$ssrContext, this.vm.$config)
    ) {
      this.createOrOverwriteMetaTag(
        "robots",
        getRobotsMetaContent("noindex, follow"),
      );
    }
  }

  private handleMetaTags() {
    Object.keys(this.meta).forEach((metaTagKey) => {
      const metaTagValue = this.meta[metaTagKey];
      if (metaTagValue && metaTagValue !== "") {
        this.createOrOverwriteMetaTag(metaTagKey, metaTagValue);
      }
    });
  }

  private handleLinkTags() {
    this.link.forEach((linkTag) => {
      this.createOrOverwriteLinkTag(linkTag.hid, linkTag);
    });
    const isArticlePage = this.vm.$route.path.match("/magazine/.*/");

    // Asked by SEO team to remove the english hreflang from the article pages
    if (isArticlePage) {
      this.i18nHead.link = this.i18nHead.link.filter(
        (x) => x.hreflang !== "en",
      );
    }
  }

  private forceTrailingSlashOnCanonical() {
    const canLinkTag = this.getCanonicalLinkTag();
    if (!canLinkTag) {
      return;
    }
    canLinkTag.href = withTrailingSlash(canLinkTag.href, true);
  }

  private forceTrailingSlashOnHrefLangTags() {
    const linkTags = this.getHreflangTags();
    linkTags.forEach((tag) => {
      // We also remove the default param (added by cloudfront) from hreflang
      const href = tag.href;
      const indexOfParams = href.indexOf("?");

      // Might brake if we have indexable urls with params
      const hrefWithoutParams = withTrailingSlash(
        href.slice(0, indexOfParams > 0 ? indexOfParams : href.length),
        true,
      );
      tag.href = hrefWithoutParams;
    });
  }

  private getSsrContextQueryObj(): any {
    if (!this.vm.$ssrContext) {
      return null;
    }

    const ssrQueryStr = this.vm.$ssrContext.req?._parsedUrl?.query || null;
    const ssrQuery = ssrQueryStr ? {} : null;

    if (ssrQueryStr && ssrQueryStr !== "") {
      const ssrQueryArr = ssrQueryStr.split("&").map((x) => x.split("="));
      ssrQueryArr.forEach((x) => {
        ssrQuery[x[0]] = x[1];
      });
    }

    return ssrQuery;
  }

  private getQueryParameters(): any {
    // const ssrQuery = this.getSsrContextQueryObj();

    // This code runs both in ssr and in client side rendering
    // so we need to check both ways for the query
    return this.vm?.$root?.$route.query || this.vm.$route.query;
  }
}

export default SeoHelper;
